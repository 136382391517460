<template>
<div class="">
  <!--    PickupPoint Form-->
  <validation-observer
      ref="pickupPointForm"
      v-slot="{invalid}"
  >
    <lenon-modal
        :title="`${updatePickupPointMode?'Update':'Create'} Pickup Point`"
        :show="pickupPointModalOpened"
        :show-overlay="deletingPickupPoint"
        size="md"
        @onClose="closePickupPointModal()"
    >
      <error-display :error="error" />
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
              v-model="selectedPickupPointId"
              :options="pickupPoints"
              label-name="label"
              value-name="id"
              placeholder="Pickup Points"
              @input="populatePickupPointsForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
              icon="TrashIcon"
          >
            <b-dropdown-item @click="deletePickupPoint()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetPickupPointForm()"
          />
        </div>
      </b-row>
      <lenon-input
          v-model="pickupPoint.label"
          name="label"
          placeholder="Enter label for pickup point"
          rules="required"
          label="Label"
      />
      <lenon-input
          v-model="pickupPoint.bus_in_fee"
          type="number"
          name="bus_in_fee"
          placeholder="Enter Fee"
          rules="required|numeric"
          label="Bus in Fee"
      />
      <lenon-input
          v-model="pickupPoint.bus_out_fee"
          type="number"
          name="bus_out_fee"
          placeholder="Enter Fee"
          rules="required|numeric"
          label="Bus out Fee"
      />
      <div class="row mb-1">
        <div class="col-12">
          <small class="text-danger"><b>If you do not find the exact location, simply select a location that is close enough. Your drivers can update it later on.</b></small>
        </div>
      </div>
      <lenon-button class="mb-1" icon="MapPinIcon" variant="outline-primary" label="Get Location" @onClick="locationModalOpened = true"/>
      <div class="row">
        <div class="col-md-6">
          <lenon-input
              v-model="pickupPoint.latitude"
              type="number"
              name="latitude"
              placeholder="Enter Latitude"
              rules="required|numeric"
              label="Latitude"
              :readonly="true"
          />
        </div>
        <div class="col-md-6">
          <lenon-input
              v-model="pickupPoint.longitude"
              type="number"
              name="longitude"
              placeholder="Enter Longitude"
              rules="required|numeric"
              label="Longitude"
              :readonly="true"
          />
        </div>
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closePickupPointModal()"
          />
          <lenon-button
              :label="updatePickupPointMode?'Update':'Create'"
              :disabled="invalid"
              :loading="pickupPointLoading"
              loading-text="Loading..."
              @onClick="updatePickupPointMode?updatePickupPoint():createPickupPoint()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
  <location-picker :location="{lat:pickupPoint.latitude,lng:pickupPoint.longitude}" :opened="locationModalOpened" @onPositionChange="handlePositionChange" @onClose="onLocationClosed"/>
</div>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import LocationPicker from '@/views/transportation/settings/pickup/LocationPicker'

export default {
  name: 'PickupPointSetup',
  components: {
    LocationPicker,
    LenonTextArea,
    ErrorDisplay,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      locationModalOpened: false,
      deletingPickupPoint: false,
      pickupPointModalOpened: false,
      updatePickupPointMode: false,
      pickupPointLoading: false,
      selectedPickupPointId: null,
      pickupPoint: {
        id: null,
        label: null,
        bus_in_fee: null,
        bus_out_fee: null,
        longitude: null,
        latitude: null,
      },
    }
  },
  computed: {
    pickupPoints() {
      return this.$store.getters['pickupPoint/pickupPoints']
    },
    selectedPickupPoint() {
      return this
          .pickupPoints.find(g => +g.id === +this.selectedPickupPointId)
    },
  },
  watch: {
    modalOpened(opened) {
      this.pickupPointModalOpened = opened
    },
    selectedPickupPointId(id) {
      if (!id) {
        this.resetPickupPointForm()
      }
    },
    id(id) {
      if (id) {
        this.selectedPickupPointId = id
      }
    },
  },
  methods: {
    handlePositionChange(position){
      this.pickupPoint.longitude = position.lng
      this.pickupPoint.latitude = position.lat
    },
    onLocationClosed(){
      this.locationModalOpened = false;
    },
    resetPickupPointForm() {
      this.updatePickupPointMode = false
      this.pickupPoint = {
        id: null,
        label: null,
        bus_in_fee: null,
        bus_out_fee: null,
        longitude: null,
        latitude: null,
      }
      this.selectedPickupPointId = null
      this.$refs.pickupPointForm.reset()
    },
    populatePickupPointsForm(ef) {
      if (this.selectedPickupPoint) {
        this.updatePickupPointMode = true
        this.pickupPoint = {
          ...this.selectedPickupPoint,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.pickupPoint.__typename
      } else {
        this.resetPickupPointForm()
      }
    },
    closePickupPointModal() {
      this.pickupPointModalOpened = false
      this.$emit('modalClosed')
    },
    updatePickupPoint() {
      this.error = {}
      if (!this.pickupPoint.id) {
        return
      }
      this.pickupPointLoading = true
      this.$http.put(`transportation/pickup-points/${this.pickupPoint.id}`,this.pickupPoint).then(res => {
            this.pickupPointLoading = false
            this.showSuccess('Updated pickup point successfully')
            this.$store.commit('pickupPoint/updatePickupPoint', res.data)
          })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to update pickupPoint')
            this.pickupPointLoading = false
          })
    },
    createPickupPoint() {
      this.error = {}
      this.pickupPointLoading = true
      this.$http.post("transportation/pickup-points",this.pickupPoint) .then(res => {
            this.showSuccess('Created pickupPoint successfully')
            this.pickupPointLoading = false
            this.$store.commit('pickupPoint/addPickupPoint', res.data)
            this.resetPickupPointForm()
          })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to create pickup point')
            this.pickupPointLoading = false
          })
    },
    deletePickupPoint() {
      if (!this.selectedPickupPointId) {
        this.showInfo('Please select a pickup point ')
        return
      }
      this.deletingPickupPoint = true
      this.$http.delete(`transportation/pickup-points/${this.selectedPickupPointId}`).then(() => {
            this.deletingPickupPoint = false
            this.showSuccess('Deleted pickup point successfully')
            this.$store.commit('pickupPoint/removePickupPoint', this.selectedPickupPointId)
            this.selectedPickupPointId = null
            this.resetPickupPointForm()
          })
          .catch(err => {
            logData(err)
            this.showError('Failed to delete pickup point')
            this.deletingPickupPoint = false
          })
    },
  },
}
</script>
