<template>
  <b-button
    :id="name"
    v-b-tooltip.hover="$store.getters['app/isLargeScreen']?$t(toolTipText):''"
    v-ripple.400="'rgba(225,225,225,0.15)'"
    :variant="variant"
    :disabled="disabled || loading"
    :class="btnClass"
    @click="handleClick"
  >
    <slot>
      <div v-if="loading">
        <b-spinner
            small
            type="grow"
            class="mr-1"
        />
        <span>{{ $t(loadingText) }}</span>
      </div>
      <div v-else>
        <feather-icon
            :icon="icon"
            style="margin-right: 4px"
        />
        <span
            v-if="!iconOnly"
            class="align-middle"
        >{{ $t(label) }}</span>
      </div>
    </slot>

  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BButton, BSpinner, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'LenonButton',
  components: {
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    name: { type: String, default: null },
    label: { type: String, default: 'Button' },
    icon: { type: String, default: 'CheckCircleIcon' },
    variant: { type: String, default: 'primary' },
    btnClass: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    iconOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    loadingText: { type: String, default: 'Loading...' },
    toolTipText: { type: String, default: '' },
  },
  methods: {
    handleClick() {
      this.$emit('onClick')
    },
  },
}
</script>

<style scoped>

</style>
