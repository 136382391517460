<template>
  <validation-provider
    ref="lenonDate"
    :vid="name"
    :name="formatName(name)"
    :rules="rules"
  >
    <b-form-group>
      <label
        v-if="label && showLabel"
        :for="`date-${name}`"
      ><span v-html="$t(label)" /> <b
        v-if="isRequired"
        class="text-danger"
        style="font-size: 15px"
      >*</b></label>
      <flat-pickr
        v-if="$store.getters['app/isLargeScreen']"
        :id="`date-${name}`"
        :name="name"
        class="form-control"
        :placeholder="label"
        :config="{altInput: true,noCalendar: hideCalendar, altFormat: enableTime?hideCalendar?'G:i K':'F j, Y, H:i':'F j, Y', enableTime: enableTime,dateFormat: enableTime?hideCalendar?'G:i K':'Y-m-d H:i':'Y-m-d'}"
        :value="value"
        mode="range"
        @input="inputChanged"
      />
      <flat-pickr
        v-else
        :id="`date-${name}`"
        :value="value"
        :name="name"
        class="form-control"
        :placeholder="label"
        :config="{mode: mode,altInput: true,disableMobile: 'true',noCalendar: hideCalendar, altFormat: enableTime?hideCalendar?'H:i':'F j, Y, H:i':'F j, Y', enableTime: enableTime,dateFormat: enableTime?hideCalendar?'H:i':'Y-m-d H:i':'Y-m-d'}"
        @input="inputChanged"
      />
      <!--      <b-form-datepicker-->
      <!--        v-else-->
      <!--        :id="`date-${name}`"-->
      <!--        v-model="selectedDate"-->
      <!--        :name="name"-->
      <!--        :placeholder="label"-->
      <!--      />-->
      <small
        v-if="showError"
        class="text-danger text-capitalize"
      >{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

import {
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationProvider } from 'vee-validate'
import { required, noFutureDate, noPastDate } from '@core/utils/validations/validations'

export default {
  name: 'LenonDatePicker',
  components: {
    BFormGroup,
    ValidationProvider,
    flatPickr,
    BFormDatepicker,
  },
  props: {
    name: {
      type: String,
      default: 'date',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    showError: { type: Boolean, default: true },
    enableTime: { type: Boolean, default: false },
    showLabel: { type: Boolean, default: true },
    hideCalendar: { type: Boolean, default: false },
    mode: { type: String, default: "single" },
  },
  data() {
    return {
      required,
      noFutureDate,
      noPastDate,
      errors: [],
      selectedDate: null,
    }
  },
  computed: {
    isRequired() {
      return this.rules.includes('required')
    },
  },
  watch: {
    selectedDate(val) {
      this.inputChanged(val)
    },
    value(val) {
      if (!this.$store.getters['app/isLargeScreen']) {
        this.selectedDate = val
      }
    },
  },
  methods: {
    formatName(name) {
      return name.replaceAll('_', ' ')
    },
    inputChanged(e) {
      this.$emit('input', e)
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
