<template>
  <lenon-modal @onOk="handleClose" @onShow="resizeMap" title="Pick Location" size="lg" :show="show" @onClose="handleClose" ok-text="Done">
    <div id="map" style="height: 300px;width: 100%;"></div>
  </lenon-modal>
</template>

<script>
import L, { Icon } from 'leaflet'
import 'leaflet-geosearch/dist/geosearch.css'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import LenonModal from '@/lenon/components/LenonModal'
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
export default {
  name: 'LocationPicker',
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    location: {
      type: Object,
      default: () => {
      }
    },
  },
  components: {
    LenonModal,
  },
  watch: {
    opened(val) {
      this.show = val
    },
    location(val) {
      if (val && val.lat && val.lng) {
        this.center[0] = this.location.lat
        this.center[1] = this.location.lng
        this.markerLatLng[0] = this.location.lat
        this.markerLatLng[1] = this.location.lng
      }
    },
  },
  data() {
    return {
      show: false,
      zoom: 7,
      markerLatLng: [8.342087719817766, -1.2672018520432458]
    }
  },
  methods: {
    handleMarkerPositionUpdate(event) {
      this.$emit('onPositionChange', event)
    },
    handleClose(e) {
      this.show = false
      this.$emit('onClose')
    },
    initializeMap() {
      const map = L.map('map', {
        fullscreenControl: true,
      })
          .setView(this.location.lat ? [this.location.lat, this.location.lng] : this.markerLatLng, this.zoom)

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
          .addTo(map)

      const marker = L.marker(this.location.lat ? [this.location.lat, this.location.lng] : this.markerLatLng, { draggable: true })
          .addTo(map)
      const onMarkerDragEnd = (event) => {
        const marker = event.target;
        const position = marker.getLatLng();
        this.handleMarkerPositionUpdate(position)
      };

      // Add dragend event listener to the marker
      marker.on('dragend', onMarkerDragEnd);
      const search = new GeoSearchControl({
        provider: new OpenStreetMapProvider(),
        style: 'bar',
        marker: {
          draggable: true,
        }
      })
      map.addControl(search)
      map.on('geosearch/showlocation', (e) => {
        this.handleMarkerPositionUpdate({
          lat: e.location.y,
          lng: e.location.x
        })
      })
      map.on('geosearch/marker/dragend', (e) => {
        this.handleMarkerPositionUpdate(e.location)
      })
    },
    resizeMap() {
      setTimeout(() => {
        this.initializeMap()
        window.dispatchEvent(new Event('resize'))
      }, 100)
    }
  },
  mounted() {
  }
}
</script>

<style>
.leaflet-container {
  color: black !important;
  font-size: 16px !important;
}

.leaflet-control-geosearch form input {
  heigh: 40px !important;
  font-size: 16px !important;
}
</style>
