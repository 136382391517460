<template>
  <div class="pickupPoints">
    <lenon-list-page
        title="Pickup Points"
        :columns="columns"
        :rows="pickupPoints"
        :table-loading="tableLoading"
        :show-create="false"
        :show-refresh="false"
        :show-search="true"
        :show-details="true"
        search-placeholder="Search"
    >
      <template slot="table-header">
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="col-12"
        >
          <b-collapse
              id="pickupPoint"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-button
                    icon="SettingsIcon"
                    label="Pickup Points"
                    tool-tip-text="Manage Pickup Points(CRUD)"
                    class="mb-1"
                    @onClick="showPickupPointsModal()"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
            v-responsive="$store.getters['app/largeScreen']"
            class="d-flex align-self-center align-items-start"
        >
          <lenon-button
              icon="SettingsIcon"
              label="Pickup Points"
              tool-tip-text="Manage Pickup Points(CRUD)"
              class=""
              @onClick="showPickupPointsModal()"
          />
        </div>
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="d-flex align-self-center align-items-center"
        >
          <lenon-button
              v-b-toggle.pickupPoint
              icon-only
              icon="MoreVerticalIcon"
              variant="flat-primary"
          />
        </div>
      </template>
      <template #bus_in_fee="{row}">
        {{row.item.bus_in_fee | currency}}
      </template>
      <template #bus_out_fee="{row}">
        {{row.item.bus_out_fee | currency}}
      </template>
<!--      <template #location="{row}">-->
<!--        <lenon-button icon-only icon="MapPinIcon" variant="flat-primary"-->
<!--                      tool-tip-text="View and update map location"-->
<!--                      @onClick="row.toggleDetails"-->
<!--        />-->
<!--      </template>-->
<!--      <template #row-details="{ row }">-->
<!--        <b-card>-->
<!--          <div class="row">-->
<!--            <div class="col-12">-->
<!--              <location-picker/>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-card>-->
<!--      </template>-->
    </lenon-list-page>

    <pickupPoints-setup
        :modal-opened="pickupPointsModalOpened"
        @modalClosed="closeModal('pickupPoints')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import PickupPointsSetup from '@/views/transportation/settings/pickup/PickupSetup.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import { VBToggle, BCollapse, BBadge } from 'bootstrap-vue'
import LenonSelect from '@/lenon/components/LenonSelect'
import LocationPicker from '@/views/transportation/settings/pickup/LocationPicker'
export default {
  name: 'PickupPoints',
  components: {
    LocationPicker,
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    PickupPointsSetup,
    LenonButton,
    BBadge,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      pickupPointsModalOpened: false,
      tableLoading: false,
    }
  },
  computed: {
    columns(){
      return [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'label',
          label: 'Label',
        },
        {
          key: 'bus_in_fee',
          label: `Bus in Fee(${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'bus_out_fee',
          label: `Bus out Fee(${this.$store.getters['auth/currency']})`,
        },
        // {
        //   key: 'location',
        //   label: `Map Location`,
        // }
      ]
    },
    pickupPoints() {
      return this.$store.getters['pickupPoint/pickupPoints']
    },
  },
  watch: {
  },
  mounted() {
    this.fetchPickupPoints()
  },
  methods: {
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'pickupPoints':
          this.pickupPointsModalOpened = false
          break
      }
    },
    showPickupPointsModal() {
      this.pickupPointsModalOpened = true
    },

    fetchPickupPoints() {
      this.tableLoading = true
      this.$http.get("transportation/pickup-points")
          .then(res => {
            this.$store.commit('pickupPoint/setPickupPoints', res.data)
          })
          .catch(err => {
            this.showError('Failed to load fee pickup points')
          }).finally(() => {
        this.tableLoading = false
      })
    },
  },
}
</script>
