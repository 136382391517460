import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    confirmAction(message = 'Are you sure ?', yes = 'Yes', no = 'Cancel', yesVariant = 'primary', noVariant = 'flat-danger') {
      return this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: yesVariant,
          okTitle: yes,
          cancelTitle: no,
          cancelVariant: noVariant,
          hideHeaderClose: false,
          centered: true,
        })
    },
  },
}
