<template>
  <div
    id="validation_errors"
    class="row"
  >
    <div
      v-if="errors.length>0"
      class="col-12 mt-1 mb-1"
    >
      <div class="row align-items-center">
        <div
          id="error_display"
          class="col-9"
        >
          <b-badge
            variant="light-danger"
            class="pt-1 full-width text-wrap"
          >
            <ul>
              <li
                v-for="(err,index) in errors"
                :key="index"
                class="text-left"
              >
                <div v-html="err" />
              </li>
            </ul>
          </b-badge>
        </div>
        <div class="col-3">
          <lenon-button
            :icon-only="true"
            icon="XIcon"
            variant="outline-danger"
            tool-tip-text="Clear Errors"
            class="float-right"
            @onClick="()=>{errors=[]}"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseAndMergeErrors } from '@/lenon/utils/helperFunctions'
import {
  BBadge,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'

export default {
  name: 'ErrorDisplay',
  components: {
    LenonButton,
    BBadge,
  },
  props: ['error'],
  data() {
    return {
      errors: [],
    }
  },
  watch: {
    error(error) {
      let errors = error?.graphQLErrors?error?.graphQLErrors[0]?.extensions?.validation: error.response?.data?.errors
      if (errors) {
        if (errors) {
          this.errors = parseAndMergeErrors(errors)
          setTimeout(() => {
            document.getElementById('error_display').scrollIntoView()
          }, 200)
        }
      } else {
        this.errors = []
      }
    },
  },
}
</script>

<style scoped>

</style>
