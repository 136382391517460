<template>
  <div
    class="lenon-profile-photo"
    style="cursor: pointer;"
  >
    <VDropdown
      v-if="photo && preview && $store.getters['app/isLargeScreen']"
      :distance="6"
      :triggers="['hover']"
      placement="right-end"
    >
      <b-avatar
        variant="light-primary"
        :size="size"
        :src="profilePhoto"
      />
      <template #popper>
        <div style="height: 300px;width: 250px;">
          <img
            style="object-fit: cover;"
            height="300px"
            width="250px"
            :src="profilePhoto"
          >
        </div>
      </template>
    </VDropdown>
    <b-avatar
      v-else
      variant="light-primary"
      :size="size"
      :src="profilePhoto"
    />
  </div>
</template>

<script>
import { BAvatar, BTooltip, BCard } from 'bootstrap-vue'

export default {
  name: 'LenonProfilePhoto',
  components: {
    BAvatar,
    BTooltip,
    BCard,
  },
  props: {
    photo: { type: String, default: null },
    size: { type: String, default: '40' },
    preview: { type: Boolean, default: true },
  },
  computed: {
    profilePhoto() {
      return `${process.env.VUE_APP_STORAGE_PATH}/${this.photo}`
    },
  },
}
</script>

<style scoped>

</style>
