<template>
  <b-overlay
    :show="tableLoading"
    :variant="darkMode?'dark':'white'"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="md"
  >
    <b-card :title="title">
      <div class="d-flex justify-content-between">
        <div class="left-actions d-flex align-self-center align-items-center">
          <lenon-input
            v-if="showSearch"
            v-model="filter"
            :placeholder="searchPlaceholder"
            prefix-icon="SearchIcon"
            :suffix-icon="null"
            name=""
            @onEnterPressed="handleSearch"
          />
          <div style="padding-top: 7px;">
            <lenon-button
              v-if="showRefresh"
              variant="flat-warning"
              icon="RefreshCwIcon"
              class="ml-1"
              :icon-only="true"
              tool-tip-text="Refresh Table"
              @onClick="handleRefresh()"
            />
          </div>
          <slot name="left-header-actions" />
        </div>
        <div class="right-actions d-flex align-self-center align-items-start">
          <slot name="right-extra-header-actions" />
        </div>
      </div>
      <b-row />
      <b-row>
        <slot name="table-header" />
      </b-row>
      <div>
        <slot name="table-content" />
      </div>
      <div v-if="!hideTable">
      <b-row>
        <b-col cols="12">
          <b-table
            ref="lenonTable"
            striped
            hover
            responsive
            :selectable="enableMultiSelect"
            select-mode="range"
            :per-page="remote?null:page"
            :current-page="remote?null:current"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :no-local-sorting="remote"
            :filter="remote?null:filter"
            :tbody-tr-class="rowClass"
            no-sort-reset
            :style="`min-height: ${minHeight}px !important;`"
            @filtered="onFiltered"
            @sort-changed="handleSortChange"
            @row-selected="handleSelectionChanged"
          >
            <template
              v-if="enableMultiSelect"
              #cell(multiselect)="row"
            >
              <b-form-checkbox
                v-model="row.rowSelected"
                @change="(selected)=>{handleSingleRowSelect(selected,row)}"
              />
            </template>
            <template
              v-if="enableMultiSelect"
              #head(multiselect)="data"
            >
              <b-form-checkbox
                @change="toggleAllSelected"
              />
            </template>
            <template
              v-if="showDetails"
              #row-details="row"
            >
              <slot
                name="row-details"
                :row="row"
              />
            </template>
            <template #cell()="data">
              <slot
                :name="data.field.key"
                :row="data"
              >
                <div v-if="showProfilePhoto && data.field.key==='photo'">
                  <lenon-profile-photo :photo="data.value"/>
                </div>
                <div v-else-if="data.field.key==='index'">
                  {{ data.index + 1 }}
                </div>
                <div v-else>
                  {{ data.value }}
                </div>
              </slot>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="justify-content-between px-1">
        <lenon-select
          id="perPageSelect"
          v-model="perPage"
          :options="pageOptions"
          @input="handleDataPerPageChange"
        />
        <b-pagination
          :value="current"
          :total-rows="total"
          :per-page="page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="handlePaginationChange"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTable, BCard, BOverlay, BRow, BCol, BPagination, BFormCheckbox,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import useAppConfig from '@core/app-config/useAppConfig'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'

const { skin } = useAppConfig()
export default {
  name: 'LenonListPage',
  components: {
    LenonProfilePhoto,
    LenonButton,
    LenonSelect,
    LenonInput,
    BOverlay,
    BCard,
    BRow,
    BTable,
    BCol,
    BPagination,
    BFormCheckbox,
  },
  props: {
    remote: {
      type: Boolean,
      default: false,
    },
    hideTable: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'List Page',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    value: {
      type: String,
      default: '',
    },
    sortColumn: {
      type: String,
      default: '',
    },
    sortDir: {
      type: String,
      default: 'asc',
    },
    styleColumn: {
      type: String,
      default: null,
    },
    perPage: {
      type: [String, Number],
      default: 10,
    },
    totalRows: {
      type: [Number, String],
      default: null,
    },
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    minHeight: {
      type: [Number, String],
      default: 0,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    enableMultiSelect: {
      type: Boolean,
      default: false,
    },
    showRefresh: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    showProfilePhoto: {
      type: Boolean,
      default: false,
    },
    rowClass: {
      type: Function,
      default: (item, type) => {

      },
    },
  },
  data() {
    return {
      pageOptions: [3, 5, 10, 20, 50, 100],
      sortBy: this.sortColumn,
      sortDirection: this.sortDir,
      sortDesc: this.sortDir.toLowerCase()!=="asc",
      filter: null,
      current: 1,
      page: 10,
    }
  },
  computed: {
    items() {
      return this.rows
    },
    fields() {
      return this.columns
    },
    darkMode() {
      return skin.value === 'dark'
    },
    total() {
      return this.totalRows || this.rows.length
    },
  },
  watch: {
    value(val){
      this.filter = val
    },
    perPage(page) {
      this.page = page
    },
    currentPage(page) {
      this.current = page
    },
    filter(val){
      this.$emit("input",val)
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    handlePaginationChange(value) {
      if (this.remote) {
        this.handleChangePage(value)
      } else {
        this.current = value
      }
    },
    handleDataPerPageChange(value) {
      if (this.remote) {
        this.handlePerPageChange(value)
      }
    },
    handleRefresh() {
      if (this.filter) {
        this.filter = null
      } else if (this.remote) {
        this.handleSearch('')
      }
    },
    handleSearch(searching) {
      this.$emit('onSearch', searching)
    },
    handleChangePage(page) {
      this.$emit('onPageChange', page)
    },
    handlePerPageChange(active) {
      this.$emit('onPerPageChange', active)
    },
    handleSortChange(sort) {
      this.$emit('onSortChange', sort)
    },
    handleSelectionChanged(items) {
      this.$emit('onRowSelect', items)
    },
    toggleAllSelected(selected) {
      if (selected) {
        this.$refs.lenonTable.selectAllRows()
      } else {
        this.$refs.lenonTable.clearSelected()
      }
    },
    handleSingleRowSelect(selected, row) {
      if (selected) {
        this.$refs.lenonTable.selectRow(row.index)
      } else {
        this.$refs.lenonTable.unselectRow(row.index)
      }
    },
  },
}
</script>

<style>
  .page-item.active {
  background-color: transparent;
  }
</style>
